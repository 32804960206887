.mac-logo {
    width: 20%;
    margin-bottom: 4.8vh;
}

.landing-h1 {
    color: #5D1725;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin: 0;
    font-size: 3vw;
}

.farm-vid {
    height: 100%;
    width: 100%;
    object-fit: cover;
    margin: 0;
    position: absolute;
}

/* src/styles.css */
.home-content-div {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    position: absolute;
    z-index: 2;
    top: 45%;
}

@media (max-width: 900px) {
    .mac-logo {
        width: 30%;
        margin-bottom: 4vh;
    }
    .landing-h1 {
        font-size: 4vw;
    }

    .home-content-div {
        top: 55%;
    }
}

@media (max-width: 450px) {
    .mac-logo {
        width: 48%;
        margin-bottom: 4vh;
    }
    .landing-h1 {
        font-size: 4vw;
    }

    .home-content-div {
        top: 40%;
    }
}
