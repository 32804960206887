@import url('https://fonts.googleapis.com/css2?family=Arsenal+SC:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arsenal+SC:ital,wght@0,400;0,700;1,400;1,700&family=Permanent+Marker&display=swap');

.coming-soon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 55%;
    width: 65%;
    background-color: #E5E4E2; 
    border:20px solid #5D1725;
    border-radius: 20px;
    margin-left: auto;
    margin-right:auto;
    margin-top:200px;
    box-shadow: 15px 15px 15px;
  }

.coming-soon-text {
    font-family: "Permanent Marker", cursive;;
    font-size: 80px; 
    font-weight: bold;
    color: #5D1725; 
    margin-bottom: -40px; 
    transform:rotate(-15deg);
    margin-left: 10px;
}

.soon-text {
    font-family: "Permanent Marker", cursive;;
    font-size: 80px; 
    font-weight: bold;
    color: #5D1725; 
    transform:rotate(-15deg);
    margin-top:20px;
    margin-left: 80px;
 }
  
  .stay-tuned-text {
    font-family: "Arsenal SC";
    font-weight: bold;
    font-size: 25px; 
    margin-left: 100px;
    margin-top:100px;
}

@media screen and (max-width: 700px) {
    .coming-soon-container {
        width:90%;
        border:5px solid #5D1725;
    }

    .coming-soon-text {
        font-size: 3em;
        margin-top: 55%;
    }

    .soon-text {
        font-size: 3em;
        margin-top:20px;
        margin-left: 60px;
    }

    .stay-tuned-text {
        margin-top:90px;
        margin-left:20%;
        font-family: bold;
    }
}





