.main-navbar {
    background: radial-gradient(circle, #d5c290, #5d1725);
    display: flex;
    padding: 1em;
    justify-content: space-evenly;
    z-index: 2000;
}

.navbar-h5 {
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: white;
    margin: 0;
}

.navbar-h5:hover {
  cursor: pointer;
  color: white;
  text-decoration: underline;
}

@media (max-width: 750px) {
    .navbar-h5 {
        font-size: 0.6em;
    }
    .main-navbar {
        padding: 0.8em;
    }
}