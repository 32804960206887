.main-content-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

.button-to-add-members{
    display:flex;
}

.main-pic-div {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
}

.outer-profile-transition-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profile-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position:relative;
    width: 50%;
    padding-bottom: 1em;
    border-radius: 0.5em;
    transition: box-shadow 0.4s ease, border 0.4s ease, transform 0.4s;
}

.delete-button-div {
    position:absolute;
    top:15px;
    right:12px;
    display: inline-block;
    transition: transform 0.3s ease;
}
.delete-button-div:hover {
    cursor: pointer;
    transform: scale(1.04);
    color:#5D1725;
}



.profile-div-transparent {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 1em;
    border-radius: 0.5em;
    width: 50%;
}

.profile-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    position:relative;
    width: 50%;
    padding-bottom: 1em;
    border-radius: 0.5em;
    transition: box-shadow 0.4s ease, border 0.4s ease, transform 0.4s;
}

.main-profile-div {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5em;
    padding-bottom: 1em;
    padding-top: 1em;
    margin-top: 5em;
    margin-bottom: 2em;
    border-radius: 0.5em;
    transition: box-shadow 0.4s ease, border 0.4s ease, transform 0.4s;
}

.main-profile-div:hover {
    cursor: pointer;
    box-shadow: 0px 5px 10px 1px rgb(197, 196, 196);
    transform: scale(1.009);
}

.profile-div:hover {
    cursor: pointer;
    box-shadow: 0px 5px 10px 1px rgb(197, 196, 196);
    transform: scale(1.009);
}
.outer-profiles-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 15px 10px -5px rgb(197, 196, 196);
    padding-top: 5em;
    padding-bottom: 5em;
}

.outer-profiles-div-modified {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 5em;
    padding-bottom: 5em;
}

.main-name-text {
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin: 0;
    color: #5D1725;
    font-size: 2.2vw;
}

.prakash-jha-img {
    width: 20%;
}

.row-profile-div {
    width: 98%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:0.5em;
    margin-bottom: 8px;
}

.member-text {
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin: 0;
    color: #5D1725;
    font-size: 2vw;
}

.headshot-img {
    width: 36%;

}

/* Profile Modal Styling*/

.contact-text:hover {
    cursor: pointer;
    text-decoration: underline;
}

.main-modal-div {
    border: 1px solid white !important;
    overflow: auto;
}

.outer-modal-div {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.outer-bio-div {
    display: flex;
    width: 100%;
    gap: 4em;
}

.bio-div {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}
.modal-headshot-img {
    width: 18%;
}

.modal-title-text {
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin: 0;
    color: #5D1725;
    font-size: 2.2vw;
}

.modal-horizontal-bar {
    width: 100%;
    height: 2px;
    background: lightgrey;
}

.job-title-text {
    font-size: 1.8vw;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.modal-bio-text {
    font-size: 1.5vw;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: rgb(104, 104, 104);
}

.contact-info-div {
    display: flex;
    gap: 2vw;
    width: 100%;
}

.contact-row-div {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}
.contact-text-maroon {
    font-size: 1.5vw;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #5D1725;
}

.contact-text-maroon:hover {
    cursor: pointer;
    text-decoration: underline;
}

@media (max-width: 750px) {
    .prakash-jha-img {
        width: 45%;
    }
    .title-text {
        font-size: 4.6vw;
    }

    .main-name-text {
        font-size: 4vw;
    }

    .headshot-img {
        width: 40%;
    }

    .row-profile-div {
        flex-direction: column;
    }
    .profile-div-transparent {
        display: none;
    }
    .member-text {
        font-size: 3.7vw;
    }

    .pic-overlay-text { 
        font-size: 4.8vw;

    }
    .profile-div {
        width: 95%;
    }
    .profile-div:hover {
        transform: scale(1.009);
    }
}
@media (max-width: 500px) {
    .modal-headshot-img {
        width: 40%;
    }

    .modal-title-text {
        font-size: 3.8vw;
    }

    .job-title-text {
        font-size: 3.4vw;
    }

    .modal-bio-text {
        font-size: 3vw;
    }

    .contact-text-maroon {
        font-size: 2.6vw;
    }

    .contact-row-div {
        gap: 2px;
    }

    .contact-info-div {
        gap: 3.2vw;
    }
}