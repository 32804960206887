.join-us-title {
    color: #5D1725;
    font-size: 2.2vw;
    margin: 0;
}

.join-us-text {
    font-size: 1.4vw;
    margin: 0;
}

.contact-name-text {
    font-size: 1.6vw;
    margin: 0;
    font-weight: bold;
    text-decoration: underline;
}

@media (max-width: 900px) {
    .join-us-title {
        font-size: 3vw;
    }
    
    .join-us-text {
        font-size: 2.5vw;
    }
    
    .contact-name-text {
        font-size: 2.7vw;
    }
}

@media (max-width: 550px) {
    .join-us-title {
        font-size: 4.2vw;
    }
    
    .join-us-text {
        font-size: 3.2vw;
    }
    
    .contact-name-text {
        font-size: 3.5vw;
    }
}