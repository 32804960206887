/* General */
* {
  box-sizing: border-box;
}

.main-div {
  overflow-x: hidden;
  height: 100vh;
  position: relative;
}
.sticky-div {
  right:0;
  bottom:0;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 4em;
  margin-right: 4em;
  z-index: 300;
}
.group-img {
    object-fit: cover;
    width: 100%;
    filter: brightness(70%);
    position: sticky;
    box-shadow: 0px 15px 10px -5px rgb(197, 196, 196);
}

.pic-overlay-text { 
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 0;
  color: white;
  font-size: 3vw;
  position: absolute;
  top: 50%;
}

.title-div {
  display: flex;
  justify-content: center;
  margin-top: 10vh;
}

.title-text {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 0;
  color: #A69F88;
  font-size: 2.5vw;
}

.title-bar {
  width: 100%;
  height: 2px;
  background: lightgrey;
}

@media (max-width: 750px) {
  .title-text {
      font-size: 4.6vw;
  }

  .pic-overlay-text { 
      font-size: 4.8vw;

  }
  .sticky-div {
    margin-right: 1.5em;
  }
}
