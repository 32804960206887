.footer-link-text {
    font-size: 1.15vw;
    font-weight: bold;
    margin: 0;
    color: white;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;

}

.footer-link-text:hover {
    cursor: pointer;
}

.footer-univ-name-text {
    color: white;
    font-size: 1.2vw;
    font-weight: bold;
    margin: 0;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.footer-grey-text {
    font-size: 1.12vw;
    color: grey;
    margin: 0;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.footer-grey-text-small {
    font-size: 0.8em;
    color: grey;
    margin: 0;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    cursor: pointer;
    display: inline;
}

.footer-grey-text-small-no-pointer {
    font-size: 0.8em;
    color: grey;
    margin: 0;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    display: inline;
}

.footer-grey-text-small-underline {
    font-size: 0.8em;
    color: grey;
    margin: 0;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-decoration: underline;
    cursor: pointer;
    display: inline;
}

.footer-top-div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}
.footer-bottom-div {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1px solid rgb(62, 61, 61);
    padding: 1.5em;
    text-align: center;
    justify-content: center;
}

.footer-contact-div {
    display: flex;
    width: 28%;
    justify-content: space-between;
}

.footer-link-div {
    display: flex;
    justify-content: space-between;
    width: 30%;
    
}

.footer-sec-link-div {
    display: flex;
    gap: 0.5em; 
    text-align: center;
    justify-content: center;
    align-items: center;
}

.footer-hortz-img-div {
    display: flex;
    width: 29%;
    margin: 0;
}

.msstate-square-white-logo {
    width: 24%;
}

@media (max-width: 900px) {
    .footer-top-div {
        flex-direction: column;
        gap:2em;
    }
    .footer-contact-div {
        width: 60%;
    }
    .footer-link-div {
        width: 100%;
        text-align: center;
        border-top: 1px solid rgb(62, 61, 61);
        padding: 2.2em;
        padding-bottom: 0;
    }
    .footer-link-text {
        font-size: 1.8vw;
    }

    .footer-univ-name-text {
        font-size: 2vw;
    }
    
    .footer-grey-text {
        font-size: 1.9vw;
    }
    .footer-hortz-img-div {
        display: none;
    }
    

}

@media (max-width: 650px) {
    
    .footer-top-div {
        flex-direction: column;
        gap:2em;
    }
    .footer-contact-div {
        width: 100%;
    }

    .footer-link-div {
        padding-left: 0.2em;
        padding-right: 0.2em;
        padding-bottom: 0;
    }
    .footer-link-text {
        font-size: 3vw;
    }

    .footer-univ-name-text {
        font-size: 3.2vw;
    }
    
    .footer-grey-text {
        font-size: 3vw;
    }
    .footer-hortz-img-div {
        display: none;
    }
    .footer-grey-text-small-underline {
        font-size: 3vw;
    }
    .footer-grey-text-small {
        font-size: 7.5vw;
    }
    .footer-bottom-div {
        padding-left: 0em;
        padding-right: 0em;
    }
    .footer-sec-link-div {
        margin-bottom: 1em;
    }

    .footer-grey-text-small-no-pointer {
        font-size: 3vw;
    }
}