/* src/CardFlip.css */
.card-container {
    perspective: 1000px;
    width: 100%;
    cursor: pointer; 
  }
  
  .card {
    width: 100%;
    aspect-ratio: 4/3;
    position: relative;
    transform-style: preserve-3d;
    transition: transform 0.5s, width 0.4s ease;
  }

  .card:hover {
    transform: scale(1.06);
  }
  
  .card-container.flipped .card {
    transform: rotateY(180deg);
  }
  
  .front, .back {
    position: absolute;
    width: 100%;
    aspect-ratio: 4/3;
    backface-visibility: hidden;
    display: flex;
    border-radius: 0.5em;
    box-shadow: 3px 3px 0px 0.5px #5D1725;
    border: 2px solid #5D1725;
    
  }
  
  .front {
    height: 100%;
  }
  
  .back {
    transform: rotateY(180deg);
  }