.research-overview-div {
  padding: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;
  text-align: center;
}

  .research-text {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.3vw;
  font-weight: bold;
  color: #4c4c4c;
}
.research-topic-text {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.15vw;
}

ol {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: bolder;
}
li {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.2vw;
}

.main-research-topic-div {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.research-topic-div {
  display: flex;
  flex-direction: column;
  width: 30%;
  border-radius: 0.5em;
}

.research-topic-img-div {
  display: flex;
  width: 100%;
  position: relative;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.research-topic-img {
  width: 100%;
  border-radius: 0.34em;
  filter: brightness(70%);
}

.front-research-topic-title {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: white;
  position: absolute;
}
.back-research-topic-title {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #A69F88;
}

.research-topic-text-div {
  padding-left: 0.8em;
  padding-right: 0.8em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ongoing-project-title {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #5D1725;
  font-size: 1.4vw;
  margin: 0;
}

.plain-ongoing-project-text {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  margin-top: 0;
}

li {
  list-style-position: inside; /* Ensure the numbers are inside the list items */
}

li::marker {
  font-size: 1.4vw;
}

.research-delete-icon-div {
  display: flex;
  margin-left: 1em;
  height: fit-content;
  transition: transform 0.3s ease;
}

.research-delete-icon-div:hover {
  cursor: pointer;
  transform: scale(1.07);
  color:#5D1725;

}

@media (max-width: 1055px) {
  .main-research-topic-div {
    flex-direction: column;
    gap: 3em;
    justify-content: center;
    align-items: center;
  }

  .research-topic-div {
    width: 75%;
  }

  .research-text {
    font-size: 1.8vw;
  }
  .research-topic-text {
    font-size: 1.9vw;
  }
  .research-overview-div {
    text-align: center;

  }
  .ongoing-project-title {
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-decoration: underline;
    color: #5D1725;
    font-size: 2.2vw;
  }
  .ongoing-project-participant-text {
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin: 0;
  }
  .front-research-topic-title {
    font-size: 2.7vw;
    margin: 0;
  }

  .ongoing-project-participant-text {
    font-size: 2vw;
    margin: 0;
  }
  .plain-ongoing-project-text {
    font-size: 2vw;
    margin: 0;
  }
  li::marker {
    font-size: 2.2vw;
  }
  
}

@media (max-width: 500px) {
  .research-text {
    font-size: 3.5vw;
  }
  .front-research-topic-title {
    font-size: 4.6vw;
    margin: 0;
  }
  .research-topic-text {
    font-size: 2.7vw;
  }
  .ongoing-project-participant-text {
    font-size: 3.1vw;
    margin: 0;
  }

  .plain-ongoing-project-text {
    font-size: 3.2vw;
  }

  .ongoing-project-title {
    font-size: 3.2vw;
  }

}
