.outer-headshot-img {
    z-index: 10;  
    width: 25em;
}
.inner-headshot-img {
    width: 17em;
    position: absolute;
    top:3.6em;
    border-radius: 50%;
    z-index: 9;
    
}