.home-header-div {
  background: #5d1725;
  display: flex;
  padding: 1em;
  padding-left: 3em;
  padding-right: 3em;
  justify-content: space-between;
  width: 100%;
}

.msstate-logo-div {
  display: flex;
  justify-content: center;
  width: 38%;
}

.header-mac-logo-div {
  display: flex;
  justify-content: center;
  width: 28%;
}

.msstate-logo {
  width: 100%;
}

.header-mac-horizontal-logo {
  width: 100%;
}

.mac-logo-mobile-white-logo {
  display: none;
}

@media (max-width: 650px) {
  .home-header-div {
    padding: 0;
    flex-direction: column;
  }
  .msstate-logo-div {
    padding: 1em;
    width: 100%;
    justify-content: flex-start;
  }

  .header-mac-logo-div {
    padding: 1em;
    justify-content: flex-start;
    width: 100%;
    background-color: black;
  }

  .msstate-logo {
    width: 100%;
  }

  .header-mac-horizontal-logo {
    display: none;
  }

  .mac-logo-mobile-white-logo {
    display: flex;
    width: 55%;
  }

}
