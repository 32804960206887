.sub-container {
  width: 95%;
  margin-top: 3em;
  margin-bottom: 5em;
}

.news-title {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.8vw;
  font-weight: bold;
  text-decoration: underline;
}

.readmore {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: #5d1725;
  font-size: 1.1vw;
  display: inline-block;
  transition: transform 0.3s ease;
}

.readmore:hover {
  transform: scale(1.04);
}

.news-content {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.5vw;
}

.containers-sub-container {
  border: 3px solid #5d1725;
  border-radius: 1em;
  width: 96%;
  box-shadow: 5px 5px 0px 1px #5D1725;
  display: flex;
  align-items: center;
  gap:1em;
  padding: 1.5em;
  justify-content: space-between;
  margin-bottom:100px;
  position:relative;
}

.author {
  font-weight: bold;
  font-size: 1.3vw;
}

.timestamp {
  font-size: 1.2vw;
}

.news-content-div {
  width: 48%;
  display: flex;
  flex-direction: column;
}

.news-img-div {
  width: 50%;
  display: flex;
  justify-content: center;
  background-color: #5D1725;
  border-radius: 0.5em;
  padding: 1em;
}

.news-img {
  width: 100%;
  height: auto;
  border-radius: 0.5em;
}

.delete-button-div-news {
  position: absolute;
  right:18px;
  top:15px;
  display: inline-block;
  transition: transform 0.3s ease;
}

.delete-button-div-news:hover {
  cursor: pointer;
  color: #5D1725;
  transform: scale(1.05);
}


@media screen and (max-width: 640px) {
  .containers-sub-container {
    flex-direction: column;
  }
  .news-img-div {
    width: 100%;
  }
  .news-img {
    width: 98%;
  }
  .news-content-div {
    width: 100%
  }
  .news-title {
    font-size: 3.5vw;
  }
  
  .readmore {
    font-size: 2.2vw;
  }
  
  .news-content {
    font-size: 2.8vw;
  }

  .author {
    font-size: 2.6vw;
  }
  
  .timestamp {
    font-size: 2.4vw;
  }

}
