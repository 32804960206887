.pub-title {
    text-decoration: underline;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin: 0;
    font-size: 18px;
}
.pub-div {
    width: 100%;
    display: flex;
    padding: 0.5em;
    border-radius: 0.3em;
    justify-content: space-between;
    transition: box-shadow 0.4s ease, transform 0.5s;
    z-index: 200;
}
.non-clickable-pub-div {
    display: flex;
    justify-content: space-between;
    padding: 0.5em;
    border-radius: 0.3em;
    text-decoration: underline !important;
    z-index: 200;
}

.pub-div:hover {
    box-shadow: 0px 0px 10px 0px rgb(197, 196, 196);
    cursor: pointer;
    transform: scale(1.01);
}
.pub-div:hover .pub-title {
    color: #5D1725;
}

.delete-icon-div {
    display: flex;
    align-self: center;
}
.delete-icon-div:hover {
    cursor: pointer;
    transform: scale(1.01);
    
}

.gs-link-div {
    display: flex;
    gap: 0.5em;
    width: fit-content;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.gs-link-div:hover {
    text-decoration: underline #5D1725;
    cursor: pointer;
}